import { writable } from "svelte/store";
import { setMany, entries, delMany } from 'idb-keyval';

type Options = {
  useBrowserStorage?: boolean;
  init?: boolean;
};

const sessionKey = 'sessionValues';

// Store for all values that needs storing for a user session
export const sessionValues = writable<Record<string, string>>({});

// Sync session values from URL search params
export const syncSessionValuesFromSearchParams = (params: URLSearchParams, opt?: Options) => {
  const newSessionValues: Record<string, string> = {};

  if (params.size) {
    // Store new session values from URL search params
    for (const [key, val] of params) {
      newSessionValues[key] = val;
    }
  }

  syncSessionValues(newSessionValues, opt);
};

// Sync store from web storage and merge with new values if any.
// Uses sessionStorage unless values are needed for functionality across tabs.
export const syncSessionValues = (newValues: Record<string, string>, options?: Options) => {
  // Throw error if not on client side to alert developer
  if (typeof window === "undefined") {
    throw new Error('500 Internal Server Error: Session values can only be interacted with in UA client.');
  }

  const opt: Options = {
    useBrowserStorage: false,
    init: false,
    ...(options || {})
  };
  const addValues = Object.keys(newValues || {}).length ? newValues : {};
  let sessionStorageValues: Record<string, string> = {};

  if (opt?.init) {
    try {
      sessionStorageValues = JSON.parse(sessionStorage.getItem(sessionKey) || `{}`);
    } catch (e) {
      sessionStorageValues = {};
      console.log('Error parsing sessionStorage values:', e);
    }

    // Set Svelte store before async operations
    sessionValues.set({
      ...sessionStorageValues,
      ...addValues,
    });

    entries()
      .then((entries: [IDBValidKey, string][]) => {
        const acrossSessionValues = {
          ...entries.reduce((acc, [key, val]) => ({ ...acc, [key as string]: val }), {})
        };

        if (Object.keys(acrossSessionValues).length) {
          // Store in Svelte store
          sessionValues.update((values) => ({
            ...acrossSessionValues,
            ...values,
          }));
        }
      });
  } else {
    // Store in Svelte store
    sessionValues.update((values) => ({
      ...values,
      ...addValues
    }));
  }

  if (opt?.useBrowserStorage && Object.keys(addValues || {}).length) {
    setMany(Object.entries(addValues));
  }

  // Always store in sessionStorage (since it's session it never gets too big)
  try {
    sessionStorage.setItem(sessionKey, JSON.stringify({
      ...sessionStorageValues,
      ...addValues
    }));
  } catch (e) {
    console.log('Error setting sessionStorage values:', e);
  }
};

export const removeSessionValues = (keys: string[]) => {
  if (typeof window === "undefined") {
    throw new Error('500 Internal Server Error: Session values can only be interacted with from UA client side.');
  }

  // Remove from Svelte store
  sessionValues.update((values) => {
    keys.forEach(key => delete values[key]);
    return values;
  });

  try {
    // Remove from browser sessionStorage
    const sessionStorageValues = JSON.parse(sessionStorage.getItem(sessionKey) || `{}`);
    keys.forEach(key => delete sessionStorageValues[key]);
    sessionStorage.setItem(sessionKey, JSON.stringify(sessionStorageValues));
  } catch (e) {
    console.log('Value could not be removed from sessionStorage (similarly, they might not be stored there):', e);
  }

  // Remove from IndexedDB
  entries().then((entries: [IDBValidKey, string][]) => {
    const keysToRemove = keys.filter(key => entries.some(([k]) => k === key));
    delMany(keysToRemove);
  });
};

export default sessionValues;
